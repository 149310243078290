import React from 'react';
import Layout from '~components/layouts/Default';
import Container from '~components/common/Container';
import H4 from '~components/typography/H4';
import H2 from '~components/typography/H2';
import H1 from '~components/typography/H1';
import P from '~components/typography/P';
import Button from '~components/common/Button';
import SubTitle from '~components/typography/Subtitle';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="E-Mail Bestätigung" desc="" noIndex>
      <Container>
        <div className="mx-auto mb-8 max-w-2xl text-center">
          <SubTitle className="mb-2">Fast angemeldet!</SubTitle>
          <H2 className="mb-4">Du hast es fast geschafft!</H2>
          <P>Betätige Deine E-Mailadresse in Deinem Postfach! Schaue auch im SPAM-Ordner nach, solltest Du E-Mail nicht finden.</P>
        </div>

        <div className="mx-auto max-w-2xl text-center">
          <P className="mb-3">Tritt unserer Community bei und erhalte Tipps und Tricks von unseren Teilnehmer:innen! Dadurch verpasst du kein Update mehr zu unseren Traininssessions!</P>
          <Button text="Zur Facebook Gruppe" href="https://web.facebook.com/groups/1072775049728649" target="_blank" />
        </div>
      </Container>
    </Layout>
  );
}
